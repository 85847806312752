/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-04-15",
    versionChannel: "nightly",
    buildDate: "2023-04-15T00:19:19.513Z",
    commitHash: "ddb45f2cc2b9c5d9d4dbc5702cfbe907ffc49f2c",
};
